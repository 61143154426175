// eslint-disable-next-line import/prefer-default-export

export enum PlacesType {
  ALL = 'all',
  BLOG = 'BLOG',
  BOARD = 'board',
  CATEGORY = 'category',
  FORUM = 'FORUM',
  GROUPHUB = 'grouphub',
  MY_GROUPHUB = 'mygrouphub',
  TKB = 'TKB',
  IDEA = 'IDEA',
  OCCASION = 'OCCASION'
}

export enum PlacesSortMenuItem {
  VIEWS = 'views',
  TOPICS_COUNT = 'topicsCount',
  MESSAGES_COUNT = 'messagesCount',
  LATEST_ACTIVITY = 'latestActivity',
  TITLE = 'title',
  COMMUNITY_STRUCTURE = 'communityStructure'
}

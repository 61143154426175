import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import type {
  CoreNodeConstraints,
  CoreNodeSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SortDirection } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext, useRef, useState } from 'react';
import { Dropdown, useClassNameMapper, Form } from 'react-bootstrap';
import { useDebounce } from 'react-use';
import useTranslation from '../../useTranslation';
import localStyles from './GroupHubListSortsAndFilters.module.pcss';
import { exactMatchOrStartsWith } from '@aurora/shared-client/helpers/search/SearchTermHelper';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * Callback function when an item in a menu is selected.
   *
   * @callback
   * @param item - the selected `SortsAndFiltersDefinition`
   */
  onChange?: (item: SortsAndFiltersDefinition) => void;
}

export interface SortsAndFiltersDefinition {
  sorts: CoreNodeSorts;
  constraints: CoreNodeConstraints;
}

export enum MembershipFilterMenuItem {
  MEMBER = 'member',
  MEMBER_AND_NONMEMBER = 'memberAndNonmember',
  NONMEMBER = 'nonmember',
  OWNER = 'owner'
}

export enum MembershipTypeFilterMenuItem {
  ALL = 'all',
  OPEN = 'open',
  CLOSED = 'closed',
  HIDDEN = 'hidden'
}

export const membershipTypeMenuItemMap: Record<MembershipTypeFilterMenuItem, CoreNodeConstraints> =
  {
    [MembershipTypeFilterMenuItem.ALL]: {},
    [MembershipTypeFilterMenuItem.OPEN]: {
      membershipType: { eq: 'open' }
    },
    [MembershipTypeFilterMenuItem.CLOSED]: {
      membershipType: { eq: 'closed' }
    },
    [MembershipTypeFilterMenuItem.HIDDEN]: {
      membershipType: { eq: 'closed_hidden' }
    }
  };

export enum GroupHubSortMenuItem {
  CREATED_DATE_ASC = 'createdDateAsc',
  CREATED_DATE_DESC = 'createdDateDesc',
  LATEST_ACTIVITY = 'latestActivity',
  MEMBER_COUNT = 'memberCount'
}

export const groupHubSortMenuItemMap: Record<GroupHubSortMenuItem, CoreNodeSorts> = {
  [GroupHubSortMenuItem.CREATED_DATE_ASC]: { creationDate: { direction: SortDirection.Asc } },
  [GroupHubSortMenuItem.CREATED_DATE_DESC]: { creationDate: { direction: SortDirection.Desc } },
  [GroupHubSortMenuItem.LATEST_ACTIVITY]: {
    messageActivityCorePropertyChangeTime: { direction: SortDirection.Desc }
  },
  [GroupHubSortMenuItem.MEMBER_COUNT]: { membersCount: { direction: SortDirection.Desc } }
};

/**
 * Filter and sorts for a grouphubs list.
 *
 * @author Martin Barotto
 */
const GroupHubListSortsAndFilters = ({
  className,
  onChange = (): Promise<void> => null
}: Props): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.GROUP_HUB_LIST_SORTS_AND_FILTERS
  );
  const { authUser, contextUser } = useContext(AppContext);
  const { isRegistered } = useRegistrationStatus();
  const isMounted = useRef(false);
  let filterUser;
  if (isRegistered) {
    if (contextUser) {
      filterUser = authUser.id === contextUser.id ? authUser : contextUser;
    } else {
      filterUser = authUser;
    }
  } else if (contextUser) {
    filterUser = contextUser;
  }
  const membershipFilterItems = [
    MembershipFilterMenuItem.MEMBER,
    MembershipFilterMenuItem.MEMBER_AND_NONMEMBER,
    MembershipFilterMenuItem.NONMEMBER,
    MembershipFilterMenuItem.OWNER
  ];
  const [selectedMembershipFilterItem, setSelectedMembershipFilterItem] =
    useState<MembershipFilterMenuItem>(
      contextUser ? MembershipFilterMenuItem.MEMBER : MembershipFilterMenuItem.MEMBER_AND_NONMEMBER
    );

  const membershipTypeFilterItems = [
    MembershipTypeFilterMenuItem.ALL,
    MembershipTypeFilterMenuItem.OPEN,
    MembershipTypeFilterMenuItem.CLOSED,
    MembershipTypeFilterMenuItem.HIDDEN
  ];
  const [selectedMembershipTypeFilterItem, setSelectedMembershipTypeFilterItem] =
    useState<MembershipTypeFilterMenuItem>(MembershipTypeFilterMenuItem.ALL);

  const groupHubSortItems = [
    GroupHubSortMenuItem.CREATED_DATE_ASC,
    GroupHubSortMenuItem.CREATED_DATE_DESC,
    GroupHubSortMenuItem.LATEST_ACTIVITY,
    GroupHubSortMenuItem.MEMBER_COUNT
  ];
  const [selectedGroupHubSortItem, setSelectedGroupHubSortItem] = useState<GroupHubSortMenuItem>(
    GroupHubSortMenuItem.MEMBER_COUNT
  );

  const [textFilter, setTextFilter] = useState<string>('');

  const membershipMenuItemMap: Record<MembershipFilterMenuItem, CoreNodeConstraints> = {
    [MembershipFilterMenuItem.MEMBER_AND_NONMEMBER]: {},
    [MembershipFilterMenuItem.MEMBER]: {
      userId: { eq: filterUser?.id }
    },
    [MembershipFilterMenuItem.NONMEMBER]: {
      userId: { neq: filterUser?.id }
    },
    [MembershipFilterMenuItem.OWNER]: {
      ownersId: { eq: filterUser?.id }
    }
  };

  /**
   * Builds a NodeConstraint matching both, the title and description fields using the provided text.
   * @param text Provided text to create the constraint
   */
  function buildNodeConstraintForTitleAndDescription(text: string): CoreNodeConstraints {
    if (text) {
      return {
        title: { matches: exactMatchOrStartsWith(text, { trim: false }) },
        description: { matches: exactMatchOrStartsWith(text, { trim: false }) }
      };
    } else {
      return {};
    }
  }

  /**
   * Call the provided callback function using all the dropdown selections and specified text.
   */
  function callOnChange(
    text: string,
    sort: GroupHubSortMenuItem,
    membership: MembershipFilterMenuItem,
    membershipType: MembershipTypeFilterMenuItem
  ): void {
    onChange({
      constraints: {
        ...membershipMenuItemMap[membership],
        ...membershipTypeMenuItemMap[membershipType],
        ...buildNodeConstraintForTitleAndDescription(text)
      },
      sorts: groupHubSortMenuItemMap[sort]
    });
  }

  function handleTextFilter(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value: text } = event.target;
    setTextFilter(text);
  }

  useDebounce(
    () => {
      if (isMounted.current) {
        callOnChange(
          textFilter,
          selectedGroupHubSortItem,
          selectedMembershipFilterItem,
          selectedMembershipTypeFilterItem
        );
      } else {
        isMounted.current = true;
      }
    },
    500,
    [textFilter]
  );

  if (textLoading) {
    return null;
  }

  function handleGroupHubSort(item: GroupHubSortMenuItem): void {
    setSelectedGroupHubSortItem(item);
    callOnChange(textFilter, item, selectedMembershipFilterItem, selectedMembershipTypeFilterItem);
  }

  function handleMembershipFilter(item: MembershipFilterMenuItem): void {
    setSelectedMembershipFilterItem(item);
    callOnChange(textFilter, selectedGroupHubSortItem, item, selectedMembershipTypeFilterItem);
  }

  function handleMembershipTypeFilter(item: MembershipTypeFilterMenuItem): void {
    setSelectedMembershipTypeFilterItem(item);
    callOnChange(textFilter, selectedGroupHubSortItem, selectedMembershipFilterItem, item);
  }

  function showMembershipFilterItem(item: MembershipFilterMenuItem): boolean {
    return contextUser
      ? item !== MembershipFilterMenuItem.MEMBER_AND_NONMEMBER &&
          item !== MembershipFilterMenuItem.NONMEMBER
      : true;
  }
  function renderMembershipFilterMenuItem(item: MembershipFilterMenuItem): React.ReactElement {
    if (showMembershipFilterItem(item)) {
      return (
        <Dropdown.Item
          active={item === selectedMembershipFilterItem}
          key={item}
          onSelect={(): void => handleMembershipFilter(item)}
          data-testid={`GH-MembershipFilterItem.${item}`}
        >
          {formatMessage(item)}
        </Dropdown.Item>
      );
    }
    return null;
  }

  function renderMembershipFilterMenu(): React.ReactElement {
    return (
      isRegistered && (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            variant={ButtonVariant.LINK}
            className={cx('lia-g-dropdown-toggle')}
            data-testid="GH-MembershipFilter-Toggle"
            aria-label={formatMessage('memberAndNonmemberFilter', {
              value: formatMessage(selectedMembershipFilterItem)
            })}
          >
            {formatMessage(selectedMembershipFilterItem)}
          </Dropdown.Toggle>
          <Dropdown.Menu popperConfig={dropdownPopperConfig} data-testid="GH-MembershipFilter-Menu">
            <Dropdown.Header>{formatMessage('filterBy')}</Dropdown.Header>
            {membershipFilterItems.map(filter => renderMembershipFilterMenuItem(filter))}
          </Dropdown.Menu>
        </Dropdown>
      )
    );
  }

  function renderMembershipTypeFilterMenuItem(
    item: MembershipTypeFilterMenuItem
  ): React.ReactElement {
    return (
      (isRegistered || item !== MembershipTypeFilterMenuItem.HIDDEN) && (
        <Dropdown.Item
          disabled={
            item === MembershipTypeFilterMenuItem.HIDDEN &&
            selectedMembershipFilterItem === MembershipFilterMenuItem.NONMEMBER
          }
          active={item === selectedMembershipTypeFilterItem}
          key={item}
          onSelect={(): void => handleMembershipTypeFilter(item)}
          data-testid={`GH-MembershipTypeFilterItem.${item}`}
        >
          {formatMessage(item)}
        </Dropdown.Item>
      )
    );
  }

  function renderMembershipTypeFilterMenu(): React.ReactElement {
    return (
      <Dropdown>
        <Dropdown.Toggle
          as={Button}
          variant={ButtonVariant.LINK}
          className={cx('lia-g-dropdown-toggle')}
          data-testid="GH-MembershipTypeFilter-Toggle"
          aria-label={formatMessage('allFilter', {
            value: formatMessage(selectedMembershipTypeFilterItem)
          })}
        >
          {formatMessage(selectedMembershipTypeFilterItem)}
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={dropdownPopperConfig}
          data-testid="GH-MembershipTypeFilter-Menu"
        >
          <Dropdown.Header>{formatMessage('filterBy')}</Dropdown.Header>
          {membershipTypeFilterItems.map(filter => renderMembershipTypeFilterMenuItem(filter))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function renderGroupHubSortMenuItem(item: GroupHubSortMenuItem): React.ReactElement {
    return (
      <Dropdown.Item
        active={item === selectedGroupHubSortItem}
        key={item}
        onSelect={(): void => handleGroupHubSort(item)}
        data-testid={`GH-SortMenuItem.${item}`}
      >
        {formatMessage(item)}
      </Dropdown.Item>
    );
  }

  function renderGroupHubSortMenu(): React.ReactElement {
    return (
      <Dropdown>
        <Dropdown.Toggle
          as={Button}
          variant={ButtonVariant.LINK}
          className={cx('lia-g-dropdown-toggle')}
          data-testid="GH-SortMenu-Toggle"
          aria-label={formatMessage('memberCountFilter', {
            value: formatMessage(selectedGroupHubSortItem)
          })}
        >
          {formatMessage(selectedGroupHubSortItem)}
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={dropdownPopperConfig} data-testid="GH-SortMenu">
          <Dropdown.Header>{formatMessage('sortBy')}</Dropdown.Header>
          {groupHubSortItems.map(sort => renderGroupHubSortMenuItem(sort))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function renderTextFilter(): React.ReactElement {
    return (
      <Form.Control
        className={cx('lia-search')}
        type="text"
        onChange={handleTextFilter}
        placeholder={formatMessage('textFilterPlaceholder')}
        aria-label={formatMessage('textFilterPlaceholder')}
        data-testid="GH-TextFilterInput"
      />
    );
  }

  return (
    <section className={cx(className, 'lia-container')}>
      {renderTextFilter()}
      {renderGroupHubSortMenu()}
      {renderMembershipFilterMenu()}
      {renderMembershipTypeFilterMenu()}
    </section>
  );
};

export default GroupHubListSortsAndFilters;

interface Options {
  /* Whether to trim the term */
  trim?: boolean;
}

function startsWith(term: string) {
  return `${term}*`;
}

/** Helper to avoid repeitive logic for creating queries in the format of `term OR term*` */
export function exactMatchOrStartsWith(term: string, options?: Options) {
  const { trim = true } = options ?? {};
  const trimmedTerm = trim ? term?.trim() ?? '' : term;

  return `${trimmedTerm} OR ${startsWith(trimmedTerm)}`;
}
